import * as React from "react";
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import Select, { components } from "react-select";

import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroVideo from './../components/Hero/Video';
import FundingsModal from "./../components/FundingsModal";
import ModalWrapper from "../components/Modal/Wrapper";
import TrainingsSlider from './../components/Training/Slider';

import ConsultingLogo from "./../svg/logo-consulting.svg";

import HowItWorksIcon1 from "./../svg/how-it-works-1.svg";
import HowItWorksIcon2 from "./../svg/how-it-works-2.svg";
import HowItWorksIcon3 from "./../svg/how-it-works-3.svg";
import HowItWorksIcon4 from "./../svg/how-it-works-4.svg";
import HowItWorksIcon5 from "./../svg/how-it-works-5.svg";
import HowItWorksIcon6 from "./../svg/how-it-works-6.svg";
import HowItWorksIcon7 from "./../svg/how-it-works-7.svg";

import ReferenceLogoBartus from "./../images/logos/bartus.jpg";
import ReferenceLogoGinoVertucci from "./../images/logos/gino-vertucci.jpg";
import ReferenceLogoTrojakGrupa from "./../images/logos/trojak-grupa.jpg";
import ReferenceLogoKoral from "./../images/logos/koral.jpg";
import ReferenceLogoKwiatek from "./../images/logos/kwiatek.jpg";
import ReferenceLogoLurso from "./../images/logos/lurso.jpg";
import ReferenceLogoPunktDrewna from "./../images/logos/punkt-drewna.jpg";
import ReferenceLogoStagors from "./../images/logos/stagors.jpg";
import ReferenceLogoTwogu from "./../images/logos/twogu.jpg";
import ReferenceLogoAgaObuwie from "./../images/logos/aga.jpg";
import ReferenceLogoKampa from "./../images/logos/kampa.jpg";

import { collaborationSlidesLeft, collaborationSlidesRightOptional } from './../../content/slides';

SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <svg role="presentation" width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM7.707 8.793C7.5184 8.61084 7.2658 8.51005 7.0036 8.51233C6.7414 8.5146 6.49059 8.61977 6.30518 8.80518C6.11977 8.99059 6.0146 9.2414 6.01233 9.5036C6.01005 9.7658 6.11084 10.0184 6.293 10.207L10.293 14.207C10.4805 14.3945 10.7348 14.4998 11 14.4998C11.2652 14.4998 11.5195 14.3945 11.707 14.207L15.707 10.207C15.8892 10.0184 15.99 9.7658 15.9877 9.5036C15.9854 9.2414 15.8802 8.99059 15.6948 8.80518C15.5094 8.61977 15.2586 8.5146 14.9964 8.51233C14.7342 8.51005 14.4816 8.61084 14.293 8.793L11 12.086L7.707 8.793Z" fill="#1F1F1F" />
    </svg>
  </components.DropdownIndicator>
);

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: null,
      activeModalType: `training`,
      isModalOpened: false,
      contactErrors: [],
      contactSuccess: '',
      fundingActive: null,
      selectedMessageType: null,
    };

    this.referencesListRef = React.createRef(null);
    this.referencesList = null;
    this.referencesListClones = [];
    this.modalWrapperRef = React.createRef(null);
    this.traniningSelectRef = React.createRef(null);
  }

  componentDidMount() {
    window.$('#map-poland').CSSMap({
      size: 750,
      tooltips: false,
      cities: true,
      tapOnce: false,
      mobileSupport: true,
      loadingText: 'Ładowanie...',
      mapStyle: 'custom',
      disableClicks: false,
      onClick: region => this.handleMapRegionClick.call(this, region),
      onSecondClick: region => this.handleMapRegionClick.call(this, region),
    });

    window.addEventListener('resize', this.handleReferencesMasonryLayout.bind(this));
    this.handleReferencesMasonryLayout();
  }

  handleReferencesMasonryLayout() {
    if (window.innerWidth >= 1440) {
      if (!this.referencesList && this.referencesListRef) {
        this.referencesList = new window.Masonry(this.referencesListRef.current, {
          columnWidth: 336,
          itemSelector: '.references-list .reference',
          gutter: 24,
          originTop: true,
        } );

        /**
         * Clone references list so the width of all the lists is equal or more than 2 * window width to avoid blank space at the end and animation jump.
         */
        const listWidth = parseInt(window.getComputedStyle(this.referencesListRef.current).getPropertyValue('width'));
        for (let i = listWidth; i <= window.innerWidth * 2; i += listWidth ) {
          const clone = this.referencesListRef.current.cloneNode(true);
          clone.setAttribute('aria-hidden', true);
          this.referencesListClones.push(clone);
          this.referencesListRef.current.after(clone);
        }
      }
    } else {
      if (this.referencesList) {
        this.referencesListClones.forEach(clone => clone.remove());
        this.referencesListClones = [];
        this.referencesList.destroy();
        this.referencesList = null;
      }
    }
  }

  handleTrainingClick(event, modal) {
    event.preventDefault();
    this.openModal(modal);
  }
  
  openModal(modal = null) {
    if (!this.state.isModalOpened) {
      this.setState(state => ({ ...state, activeModal: modal, isModalOpened: true }));
    }
  }

  closeModal() {
    this.setState(state => ({ ...state, activeModal: null, isModalOpened: false }));
  }

  handleWorkAsCoachButtonClick(event) {
    event.preventDefault();

    const headerHeight = parseInt(document.querySelector('#masthead').offsetHeight);
    const contactForm = document.querySelector('.contact');

    this.setState(state => ({
      ...state,
      selectedMessageType: { 
        value: 'Chciałbym wziąć udział w szkoleniu',
        label: 'Chciałbym wziąć udział w szkoleniu',
      },
    }));

    window.scroll({
      top: contactForm.offsetTop - headerHeight - 20,
      behavior: 'smooth',
    });
  }

  handleMapRegionClick(region) {
    this.setState(state => ({
      ...state,
      fundingActive: region.children('a').prop('hash').slice(1),
    }));
  }

  handleFundingsModalCloseBtn(event) {
    event.preventDefault();
    this.setState(state => ({
      ...state,
      fundingActive: null,
    }));
  }

  onContactFormSubmit(event) {
    event.preventDefault();
    const contactForm = event.target;
    const { first_name, email, phone, terms, voivodeship, message_type, message } = event.target.elements;

    const elements = {
      first_name,
      email,
      phone,
      terms,
      voivodeship,
      message_type,
      message,
    };

    const formData = new FormData();
    for (const param in elements) {
      if (elements[param].type === `checkbox`) {
        if (elements[param].checked) {
          formData.append(param, elements[param].value);
        } else {
          formData.append(param, ``);
        }
      } else {
        formData.append(param, elements[param].value);
      }
    }

    this.setState(state => ({ ...state, contactErrors: [], contactSuccess: '' }));

    fetch(process.env.NODE_ENV === 'production' ? '/contact.php' : 'http://localhost/contact.php', {
      method: 'POST',
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        const headerHeight = parseInt(document.querySelector('#masthead').offsetHeight);

        if (data.errors) {
          this.setState(state => ({ ...state, contactErrors: data.errors }));
        } else if (data.success && data.message) {
          this.setState(state => ({ ...state, contactSuccess: data.message }));
        }

        window.scroll({
          top: contactForm.offsetTop - headerHeight - 20,
          behavior: 'smooth',
        });
      });
  }

  handleIndividualOfferClick() {
    this.setState(state => ({
      ...state,
      selectedMessageType: { 
        value: 'Chciałbym pozyskać dotację', 
        label: 'Chciałbym pozyskać dotację' 
      },
    }));
  }

  handleTrainingInterestedButtonClick(event) {
    event.preventDefault();

    const headerHeight = parseInt(document.querySelector('#masthead').offsetHeight);
    const contactForm = document.querySelector('.contact');

    this.closeModal();
    this.setState(state => ({
      ...state,
      selectedMessageType: { 
        value: 'Chciałbym wziąć udział w szkoleniu',
        label: 'Chciałbym wziąć udział w szkoleniu',
      },
    }));

    window.scroll({
      top: contactForm.offsetTop - headerHeight - 20,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <Layout>
        <Seo />
        <section className="hero">
          <HeroVideo />
          <div className="hero-description">
            <a href="#referencje" className="what-clients-say">
              <div className="what-clients-say-text">
                Sprawdź co mówią o nas <strong>nasi klienci</strong>
              </div>
              <svg role="presentation" width={33} height={29} viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 14.5834H31.1529" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.3985 2L31.1529 14.5833L18.3985 27.1667" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
            <figure className="hero-description-quote">
              <blockquote className="hero-description-quote-content">
                <p>Pomagamy przedsiębiorcom rozwijać firmy poprzez doradztwo i szkolenia, pozyskujemy dotacje dostosowane do twoich potrzeb!</p>
              </blockquote>
              <figcaption className="hero-description-quote-author">
                &#126; KAROL JUCHA CEO
              </figcaption>
            </figure>
          </div>
        </section>

        <section id="jak-to-dziala" className="section how-it-works">
          <div className="container">
            <h2 className="section-title">Jak to działa?</h2>
            <ul className="how-it-works-steps">
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">1</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon1} alt="" width={64} height={64} />
                </div>
                <div className="how-it-works-step-title">
                  Zgłaszasz się
                </div>
              </li>
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">2</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon2} alt="" width={64} height={58} />
                </div>
                <div className="how-it-works-step-title">
                  Szukamy programu <br />wspierającego finansowanie
                </div>
              </li>
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">3</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon3} alt="" width={64} height={48} />
                </div>
                <div className="how-it-works-step-title">
                  Pomagamy dopełnić <br />wszystkie formalności
                </div>
              </li>
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">4</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon4} alt="" width={64} height={26} />
                </div>
                <div className="how-it-works-step-title">
                  Przygotujemy za Ciebie <br />wszystkie dokumenty
                </div>
              </li>
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">5</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon5} alt="" width={61} height={64} />
                </div>
                <div className="how-it-works-step-title">
                  Podpisujesz umowę
                </div>
              </li>
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">6</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon6} alt="" width={64} height={58} />
                </div>
                <div className="how-it-works-step-title">
                  Organizujemy dla <br />Ciebie szkolenie
                </div>
              </li>
              <li className="how-it-works-step">
                <div className="how-it-works-step-num">7</div>
                <div className="how-it-works-step-icon-box">
                  <img className="how-it-works-step-icon" src={HowItWorksIcon7} alt="" width={64} height={63} />
                </div>
                <div className="how-it-works-step-title">
                  Otrzymujesz zwrot środków
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section id="nasze-szkolenia" className="section our-trainings">
          <div className="container">
            <h2 className="section-title">Nasze szkolenia</h2>
            <p className="section-content">Wybierz interesujące szkolenie, a my <br />pozyskamy dla Ciebie potrzebne środki</p>
          </div>
          
          <TrainingsSlider 
            onTrainingButtonClick={this.handleTrainingClick.bind(this)} 
            onWorkAsCoachButtonClick={this.handleWorkAsCoachButtonClick.bind(this)}
          />
        </section>

        <section id="wspolpraca" className="section collaboration">
          <div className="container">
            <div className="our-team">
              <h2 className="section-title">Nasz zespół</h2>
              <p>to wykwalifikowani specjaliści z dziedzin zarządzania, marketingu, prawa, podatków, aktorzy, czy specjaliści techniczni. Do każdego problemu podchodzimy indywidualnie, porzucając bariery, czy szablonowe podejście. Tworzymy rozwiązania łączące efektywność z kreatywnością, rozwiązujemy przyczyny problemów.</p>
              <div className="collaboration-slider">
                <nav className="collaboration-slider-nav">
                  <button type="button" className="collaboration-slider-nav-btn swiper-button-prev">
                    <svg role="presentation" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <rect width={32} height={32} rx={16} fill="white" fillOpacity="0.3" />
                        <path d="M14 10L20 16.5L14 23" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </button>
                  <button type="button" className="collaboration-slider-nav-btn swiper-button-next">
                    <svg role="presentation" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <rect width={32} height={32} rx={16} transform="matrix(-1 0 0 1 32 0)" fill="white" fillOpacity="0.3" />
                        <path d="M18 10L12 16.5L18 23" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </button>
                </nav>
                <nav className="collaboration-slider-pagination swiper-pagination"></nav>
                <Swiper
                  spaceBetween={20}
                  loop={true}
                  navigation={{
                    prevEl: '.collaboration .our-team .swiper-button-prev',
                    nextEl: '.collaboration .our-team .swiper-button-next',
                  }}
                  autoplay={true}
                  a11y={{
                    firstSlideMessage: 'To jest pierwszy slajd',
                    lastSlideMessage: 'To jest ostatni slajd',
                    nextSlideMessage: 'Następny slajd',
                    paginationBulletMessage: 'Idź do slajdu {{index}}',
                    prevSlideMessage: 'Poprzedni slajd'
                  }}
                  pagination={{
                    el: '.collaboration .our-team .swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                  }}
                >
                  {collaborationSlidesLeft.map((slide, index) => {
                    const image = this.props.data.slides.edges.find(item => item.node.relativePath === slide.image);

                    return (
                      <SwiperSlide key={index}>
                        <GatsbyImage image={getImage(image.node)} alt={slide.alt} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>

            <div className="trainings">
              <h2 className="section-title">Szkolenia</h2>
              <p>przekazujemy swoje doświadczenie i wiedzę, pozwalamy uczyć się na naszych błędach, by Twój zespół zaoszczędził pieniądze i czas. Organizujemy usługi otwarte oraz zamkniete, które nie tylko rozwijają każdego uczestnika, ale także pozwolą mu spojrzeć na problemy przedsiębiorstwa z innej perspektywy.</p>
              <div className="collaboration-slider">
                <nav className="collaboration-slider-nav">
                  <button type="button" className="collaboration-slider-nav-btn swiper-button-prev">
                    <svg role="presentation" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <rect width={32} height={32} rx={16} fill="white" fillOpacity="0.3" />
                        <path d="M14 10L20 16.5L14 23" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </button>
                  <button type="button" className="collaboration-slider-nav-btn swiper-button-next">
                    <svg role="presentation" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <rect width={32} height={32} rx={16} transform="matrix(-1 0 0 1 32 0)" fill="white" fillOpacity="0.3" />
                        <path d="M18 10L12 16.5L18 23" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </button>
                </nav>
                <nav className="collaboration-slider-pagination swiper-pagination"></nav>
                <Swiper
                  spaceBetween={20}
                  loop={true}
                  navigation={{
                    prevEl: '.collaboration .trainings .swiper-button-prev',
                    nextEl: '.collaboration .trainings .swiper-button-next',
                  }}
                  autoplay={true}
                  a11y={{
                    firstSlideMessage: 'To jest pierwszy slajd',
                    lastSlideMessage: 'To jest ostatni slajd',
                    nextSlideMessage: 'Następny slajd',
                    paginationBulletMessage: 'Idź do slajdu {{index}}',
                    prevSlideMessage: 'Poprzedni slajd'
                  }}
                  pagination={{
                    el: '.collaboration .trainings .swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                  }}
                >
                  {collaborationSlidesRightOptional.map((slide, index) => {
                    const image = this.props.data.slides.edges.find(item => item.node.relativePath === slide.image);

                    return (
                      <SwiperSlide key={index}>
                        <GatsbyImage image={getImage(image.node)} alt={slide.alt} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section id="oferta-bur" className="section bur">
          <div className="container">
            <div className="bur-inner">
              <h2 className="bur-title">Sprawdź nasze szkolenia otwarte w BUR</h2>
              <a className="bur-check-out-btn" href="https://uslugirozwojowe.parp.gov.pl/wyszukiwarka/uslugi/szukaj?dostawcyUslug%5B%5D=117167&mozliwoscDofinansowania=0" target="_blank" rel="noreferrer nofollow">
                <svg role="presentation" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 3H7C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9 12L11.25 14L15 10" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>Sprawdź tutaj</span>
              </a>
            </div>
            <p className="bur-individual-offer">
              Zapytaj o indywidualną ofertę szkoleń zamkniętych o dowolnej tematyce!
              <a className="bur-individual-offer-link" href="#zglos-sie" onClick={this.handleIndividualOfferClick.bind(this)}>
                Jestem zainteresowany
                <svg role="presentation" width={9} height={16} viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 1.5L7.5 8L1.5 14.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </p>
          </div>
        </section>

        <section id="finansowanie" className="section funding">
          <div className="funding-map">
            <div className="container">
              <h2 className="section-title">Sprawdź możliwości finansowania <br />szkoleń dla Twojego obszaru</h2>
              <p className="section-content">Wystarczy kliknąć w wybrane województwo</p>

              <div className="map">
                <div className="map-bg"></div>
                <div id="map-poland">
                  <ul className="poland">
                    <li className="pl1"><a href="#dolnoslaskie" rel="nofollow">Dolnośląskie</a></li>
                    <li className="pl2"><a href="#kujawsko-pomorskie" rel="nofollow">Kujawsko-pomorskie</a></li>
                    <li className="pl3"><a href="#lubelskie" rel="nofollow">Lubelskie</a></li>
                    <li className="pl4"><a href="#lubuskie" rel="nofollow">Lubuskie</a></li>
                    <li className="pl5"><a href="#lodzkie" rel="nofollow">Łódzkie</a></li>
                    <li className="pl6"><a href="#malopolskie" rel="nofollow">Małopolskie</a></li>
                    <li className="pl7"><a href="#mazowieckie" rel="nofollow">Mazowieckie</a></li>
                    <li className="pl8"><a href="#opolskie" rel="nofollow">Opolskie</a></li>
                    <li className="pl9"><a href="#podkarpackie" rel="nofollow">Podkarpackie</a></li>
                    <li className="pl10"><a href="#podlaskie" rel="nofollow">Podlaskie</a></li>
                    <li className="pl11"><a href="#pomorskie" rel="nofollow">Pomorskie</a></li>
                    <li className="pl12"><a href="#slaskie" rel="nofollow">Śląskie</a></li>
                    <li className="pl13"><a href="#swietokrzyskie" rel="nofollow">Świętokrzyskie</a></li>
                    <li className="pl14"><a href="#warminsko-mazurskie" rel="nofollow">Warmińsko-mazurskie</a></li>
                    <li className="pl15"><a href="#wielkopolskie" rel="nofollow">Wielkopolskie</a></li>
                    <li className="pl16"><a href="#zachodniopomorskie" rel="nofollow">Zachodniopomorskie</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <FundingsModal active={this.state.fundingActive} handleCloseBtnClick={this.handleFundingsModalCloseBtn.bind(this)} />
          </div>

          <blockquote className="quote-investment">
            <div className="container">
              <p>Najlepsza inwestycja to inwestycja w siebie!</p>
            </div>
          </blockquote>

          <div className="container">
            <article className="consulting">
              <img src={ConsultingLogo} alt="VSS Consulting" className="consulting-logo" width={155} height={78} />
              <h2 className="consulting-heading">Zajmujemy się pozyskiwaniem dotacji</h2>
              <div className="consulting-description">
                <p>Znajdziemy finansowanie odpowiadające do Twojego zapotrzebowania</p>
              </div>
              <a href="#zglos-sie" className="consulting-sign-up">Zgłoś się</a>
            </article>
          </div>
        </section>

        <section id="referencje" className="section references">
          <div className="container">
            <h2 className="section-title">Nasz zespół pozyskał środki <br />dla ponad 100 różnych klientów</h2>
            <p className="section-content">Przekonaj się co o nas sądzą</p>
          </div>

          <div className="references-slider">
            <div className="references-wrapper">
              <ul className="references-list" ref={this.referencesListRef}>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoBartus} alt="Bartuś" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>Bartuś</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Działania prowadzone przez firmę koordynowane są przez osoby, których zaangażowanie i odpowiedzialność zawsze są gwarancją rzetelnie wykonanej pracy. Dzięki wykazywanej kreatywności i zdolnościom organizacyjnym, całość współpracy zawsze przebiega zgodnie z planem. Polecam!</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoGinoVertucci} alt="Tomasz Pawlik" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <div>Gino Vertucci</div>
                        <h3>Tomasz Pawlik</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Firma VSS sp. z o.o. świadczyła dla nas usługi z zakresu szkoleń badawczo - rozwojowych oraz cykl szkoleń z zarządzania rozwiązaniami do pracy zdalnej.<br />
                      Kadra Naszego przedsiębiorstwa, będąca uczestnikami szkoleń zdobyła niezbędą wiedzę, jak sformułować ideę, wykonać jej analizę innowacyjności, zaplanować zakres badań i przygotować ich harmonogram czasowy oraz finansowy w kontekście pozyskania finansowania zewnętrznego. Nabyta została również wiedza z zakresu technik dotyczących pracy zdalnej, w tym obsługi podstawowych programów oraz cyberbezpieczeństwa.</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoTrojakGrupa} alt="Grupa Trojak" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>Grupa Trojak</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>W ramach świadczonych przez firmę usług uzyskaliśmy wsparcie w zakresie doradztwa, które zrealizowane zostało z najwyższą dbałością o wysoką jakość świadczonych usług i wzajemnych relacji.<br />
                      Terminowość i zaangażowanie specjalistów pozwoliło nam pozyskać środki na specjalistyczne doradztwo w obszarze: monitorowania trendów rynkowych oraz organizacji i zarządzania przedsiębiorstwem.</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoKoral} alt="Koral" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>Koral</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Przedstawiciele firmy bardzo profesjonalnie i indywidualnie podchodzą do każdego przedsiębiorstwa, zagłębiając się w przyczynę problemów i z pełnym zaangażowaniem realizują zamierzone cele. Polecam skorzystanie z usług świadczonych przez firmę!</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoKwiatek} alt="Kwiatek - Cukiernia" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>Kwiatek - Cukiernia</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>W ramach świadczonych przez firmę usług zdobyliśmy specjalistyczną wiedzę w obszarze trendów rynkowych, aktualnych potrzeb klientów oraz ich preferencji.<br />
                      Skorzystaliśmy również z możliwość szkoleń badawczo - rozwojowych, prowadzonych w bardzo miłej atmosferze przez trenerów posiadających odpowiednie kwalifikacje.<br />
                      Z pomocą firmy VSS Sp. z o.o., która rzetelnie wywiązywała się ze swoich obowiązków możemy cieszyć się rozwojem naszego przedsiębiorstwa. Polecam, solidne partnerstwo w biznesie.</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoLurso} alt="LURSO" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <div>LURSO</div>
                        <h3>Łukasz Stawowy</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Podczas przeprowadzonych szkoleń materiał przekazany została nam przez wyszkolonych trenerów w sposób rzeczowy i zrozumiały.<br />
                      Postępy jakie osiągnęliśmy dzięki tym usługom są ogromne i w pełni zawdzięczamy ten sukces rzetelnym pracownikom firmy VSS sp.z o.o.</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoPunktDrewna} alt="Punkt Drewna" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>Punkt Drewna</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Usługa rozwojowa w pełni spełniła moje oczekiwania pod względem jakości i zawartości merytorycznej. Zdecydowanie polecam skorzystanie z usług oferowanych przez firmę VSS Sp. z o.o. każdemu przedsiębiorcy, który chce zadbać o rozwój swojej firmy.<br />
                      Usługi oferowane przez firmę VSS sp.z o.o. są w pełni kompleksowe i obejmują szeroki zakres doradztwa oraz szkoleń.</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoStagors} alt="STAGÓRS" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>STAGÓRS</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Szkolenie zostało przeprowadzone na wysokim poziomie. Zarówno organizacja jak i sam przebieg szkolenia spełniły nasze oczekiwania. Przeprowadzone zostało profesjonalnie i adekwatnie do potrzeb pracowników firmy, co spotkało się się z niezwykle wysoką oceną doświadczonego zespołu. Szkolenie z całą pewnością przyniesie wymierne korzyści w firmie.</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoTwogu} alt="TWOGU" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>TWOGU</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Ogromną pomocą ze strony VSS Sp.z o.o. było rozwiązanie wewnętrznych problemów przedsiębiorstwa, związanych z błędami w komunikacji, prawidłowym przepływem informacji oraz koordynacji pracy na poszczególnych etapach. Dziękujemy!</p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoAgaObuwie} alt="Aga Obuwie" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>Aga Obuwie</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>Z przyjemnością stwierdzamy, że wybór firmy doradczej VSS sp. z o.o. było dobrą decyzją, a współpraca z profesjonalnym, doświadczonym zespołem pozwoliła zrealizować wszystkie założenia wspólnych projektów. </p>
                    </blockquote>
                  </figure>
                </li>
                <li className="reference">
                  <figure>
                    <figcaption className="reference-author">
                      <div className="reference-author-img">
                        <img src={ReferenceLogoKampa} alt="KAMPA" width={48} height={48} />
                      </div>
                      <div className="reference-author-name">
                        <h3>KAMPA</h3>
                      </div>
                    </figcaption>
                    <blockquote className="reference-quote">
                      <p>VSS Sp. z o.o. od roku pomaga nam rozwijać się i zdobywać nowe kompetencje. W ramach świadczonego wsparcia przeprowadzone zostało szkolenie w zakresie konstrukcji obuwia, które zrealizowane zostało w sposób sumienny i profesjonalny, przez wykwalifikowanych trenerów. Dzięki wsparciu specjalistów udało się nam osiągnąć wzrost jakości oferowanych usług.</p>
                    </blockquote>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section id="zglos-sie" className="section contact">
          <div className="container">
            <h2 className="section-title">Zgłoś się do nas</h2>
            <p className="section-content">Odpowiadamy w ciągu 12h</p>

            <div className="contact-wrapper">
              <form className="contact-form" method="post" onSubmit={this.onContactFormSubmit.bind(this)} noValidate>
                <ul className="form-messages">
                  {this.state.contactErrors && this.state.contactErrors.map((error, key) => <li key={key} className="form-error-message">{error}</li>)}
                  {this.state.contactSuccess && <li className="form-success-message">{this.state.contactSuccess}</li>}
                </ul>

                <label className="form-control">
                  <span className="form-label">Imię</span>
                  <input className="form-input" type="text" name="first_name" autoComplete="given-name" placeholder="Wpisz swoje imię..." />
                </label>
                <label className="form-control">
                  <span className="form-label">E-mail</span>
                  <input className="form-input" type="email" name="email" autoComplete="email" placeholder="Wpisz swój e-mail..." />
                </label>
                <label className="form-control">
                  <span className="form-label">Numer telefonu</span>
                  <input className="form-input" type="tel" name="phone" autoComplete="tel" placeholder="Wpisz swój numer telefonu..." />
                </label>
                <div className="form-control">
                  <label id="contact-form-voivodeship-label" className="form-label" htmlFor="contact-form-voivodeship-select">Województwo</label>
                  <Select 
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="voivodeship"
                    placeholder="Wybierz województwo"
                    aria-labelledby="contact-form-voivodeship-label"
                    inputId="contact-form-voivodeship-select"
                    options={[
                      { value: 'dolnośląskie', label: 'dolnośląskie' },
                      { value: 'kujawsko-pomorskie', label: 'kujawsko-pomorskie' },
                      { value: 'lubelskie', label: 'lubelskie' },
                      { value: 'lubuskie', label: 'lubuskie' },
                      { value: 'łódzkie', label: 'łódzkie' },
                      { value: 'małopolskie', label: 'małopolskie' },
                      { value: 'mazowieckie', label: 'mazowieckie' },
                      { value: 'opolskie', label: 'opolskie' },
                      { value: 'podkarpackie', label: 'podkarpackie' },
                      { value: 'podlaskie', label: 'podlaskie' },
                      { value: 'pomorskie', label: 'pomorskie' },
                      { value: 'śląskie', label: 'śląskie' },
                      { value: 'świętokrzyskie', label: 'świętokrzyskie' },
                      { value: 'warmińsko-mazurskie', label: 'warmińsko-mazurskie' },
                      { value: 'wielkopolskie', label: 'wielkopolskie' },
                      { value: 'zachodniopomorskie', label: 'zachodniopomorskie' },
                    ]}
                    components={{ DropdownIndicator }}
                    isSearchable={false}
                  />
                </div>
                <div className="form-control">
                  <label id="contact-form-message-type-label" className="form-label" htmlFor="contact-form-message-type-select">Rodzaj wiadomości</label>
                  <Select 
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="message_type"
                    placeholder="Wybierz rodzaj"
                    aria-labelledby="contact-form-message-type-label"
                    inputId="contact-form-message-type-select"
                    options={[
                      { value: 'Chciałbym pozyskać dotację', label: 'Chciałbym pozyskać dotację' },
                      { value: 'Chciałbym wziąć udział w szkoleniu', label: 'Chciałbym wziąć udział w szkoleniu' },
                    ]}
                    components={{ DropdownIndicator }}
                    isSearchable={false}
                    value={this.state.selectedMessageType}
                    onChange={(option, select) => {
                      if (select.action === `select-option`) {
                        this.setState(state => ({
                          ...state,
                          selectedMessageType: {
                            value: option.value,
                            label: option.label,
                          },
                        }));
                      }
                    }}
                  />
                </div>
                <label className="form-control form-checkbox">
                  <input className="form-input-checkbox" type="checkbox" name="terms" />
                  <span className="form-checkbox-checkmark"></span>
                  <span className="form-label">Zapoznałem/am się z <a href="#">regulaminem</a> i <a href="#">polityką prywatności</a> oraz akceptuję <a href="#">cookies</a></span>
                </label>
                <input type="hidden" name="message" value="" autoComplete="off" />
                <button className="contact-form-submit" type="submit">
                  <span>Wyślij zgłoszenie</span>
                  <svg role="presentation" width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.91202 12.4998H4.00002L2.02302 4.6348C2.01036 4.5891 2.00265 4.54216 2.00002 4.4948C1.97802 3.7738 2.77202 3.2738 3.46002 3.6038L22 12.4998L3.46002 21.3958C2.78002 21.7228 1.99602 21.2368 2.00002 20.5288C2.00204 20.4655 2.01316 20.4029 2.03302 20.3428L3.50002 15.4998" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </form>

              <section className="contact-address-box">
                <h3 className="contact-address-box-title">Dane kontaktowe</h3>
                <address className="contact-address-box-inner">
                  <p className="company-name">VSS Sp. z o.o.</p>
                  <p className="tablet">
                    <svg role="presentation" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.554 6.24003L7.17099 2.33503C6.78099 1.88503 6.06599 1.88703 5.61299 2.34103L2.83099 5.12803C2.00299 5.95703 1.76599 7.18803 2.24499 8.17503C5.10661 14.1 9.88503 18.8851 15.806 21.755C16.792 22.234 18.022 21.997 18.85 21.168L21.658 18.355C22.113 17.9 22.114 17.181 21.66 16.791L17.74 13.426C17.33 13.074 16.693 13.12 16.282 13.532L14.918 14.898C14.8482 14.9712 14.7562 15.0195 14.6563 15.0354C14.5564 15.0513 14.4541 15.0339 14.365 14.986C12.1354 13.7021 10.286 11.8503 9.00499 9.61903C8.95702 9.52978 8.93964 9.42726 8.95554 9.32719C8.97144 9.22711 9.01972 9.13502 9.09299 9.06503L10.453 7.70403C10.865 7.29003 10.91 6.65003 10.554 6.23903V6.24003Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    +48 519 581 670
                    <br />
                    <svg role="presentation" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 11.083C1.99997 10.4399 2.15502 9.80619 2.452 9.23573C2.74898 8.66527 3.17914 8.17483 3.706 7.80599L9.706 3.60599C10.3784 3.13529 11.1792 2.88281 12 2.88281C12.8208 2.88281 13.6216 3.13529 14.294 3.60599L20.294 7.80599C20.8209 8.17483 21.251 8.66527 21.548 9.23573C21.845 9.80619 22 10.4399 22 11.083V19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V11.083Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3 10L9.7509 15.2352C10.3893 15.7303 11.1825 16 12 16C12.8175 16 13.6107 15.7303 14.2491 15.2352L21 10" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    biuro@vss.com.pl
                  </p>
                  <p className="tablet">
                    <svg role="presentation" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 2C9.87827 2 7.84344 2.84285 6.34315 4.34315C4.84285 5.84344 4 7.87827 4 10C4 11.892 4.402 13.13 5.5 14.5L12 22L18.5 14.5C19.598 13.13 20 11.892 20 10C20 7.87827 19.1571 5.84344 17.6569 4.34315C16.1566 2.84285 14.1217 2 12 2V2Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    34-100 Tomice ul. Dworska 51
                  </p>
                  <p>
                    <strong>NIP: </strong>9512494145
                    <br />
                    <strong>REGON: </strong>384891620
                    <br />
                    <strong>KRS: </strong>0000814361
                  </p>
                  <p className="bank-account">
                    <strong>Numer konta bankowego: </strong>
                    <br />
                    82 1020 4900 0000 8102 3257 5059
                  </p>
                  <p className="mobile">
                    <strong>Numer kontaktowy: </strong>+48 519 581 670
                    <br />
                    <strong>E-mail: </strong>biuro@vss.com.pl
                  </p>
                  <p className="mobile">
                    <strong>34-100 Tomice ul. Dworska 51</strong>
                  </p>
                </address>
              </section>
            </div>
          </div>
        </section>

        <ModalWrapper 
          type={this.state.activeModalType}
          activeModal={this.state.activeModal} 
          onCloseButtonClick={this.closeModal.bind(this)} 
          onInterestedButtonClick={this.handleTrainingInterestedButtonClick.bind(this)} 
          ref={this.modalWrapperRef} 
        />
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    slides: allFile(filter: {relativeDirectory: {eq: "slides"}}) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              placeholder: BLURRED,
              quality: 100,
              breakpoints: [400, 768, 1200],
              width: 1200
            )
          }
        }
      }
    }
  }
`;
