import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Card from './Card';

import trainings from './../../../content/trainings';

SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

const Slider = ({ onTrainingButtonClick, onWorkAsCoachButtonClick }) => (
  <div className="our-trainings-slider">
    <Swiper
      slidesPerView={1}
      spaceBetween={32}
      navigation={{
        prevEl: '.our-trainings .swiper-button-prev',
        nextEl: '.our-trainings .swiper-button-next',
      }}
      a11y={{
        firstSlideMessage: 'To jest pierwszy slajd',
        lastSlideMessage: 'To jest ostatni slajd',
        nextSlideMessage: 'Następny slajd',
        paginationBulletMessage: 'Idź do slajdu {{index}}',
        prevSlideMessage: 'Poprzedni slajd'
      }}
      breakpoints={{
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
      onTouchStart={swiper => swiper.el.classList.add('swiper-container-shadow')}
      onTouchEnd={swiper => swiper.el.classList.remove('swiper-container-shadow')}
    >
      {trainings && trainings.map((training, index) => {
        return (
          <SwiperSlide key={index}>
            <Card {...training} onButtonClick={onTrainingButtonClick} />
          </SwiperSlide>
        );
      })}

      {/* Work as a coach slide */}
      <SwiperSlide>
        <article className="training-card training-card-slide">
          <div className="training-card-cover-image">
            <StaticImage 
              src="./../../images/trainings/covers/pracuj-jako-trener.jpg" 
              layout="constrained" placeholder="blurred" 
              quality={100} width={520} alt="Pracuj jako trener" 
            />
          </div>
          <div className="training-card-description">
            <h3 className="training-card-title">
              Pracuj jako trener
              <span className="training-card-subtitle">
                Jeżeli chciałbyś prowadzić szkolenia, zgłoś się
              </span>
            </h3>
            <button type="button" className="training-card-button" onClick={onWorkAsCoachButtonClick}>
              Zgłoś się
            </button>
          </div>
        </article>
      </SwiperSlide>
    </Swiper>
    <nav className="our-trainings-slider-nav">
      <button type="button" className="our-trainings-slider-nav-btn swiper-button-prev">
        <svg role="presentation" width={43} height={44} viewBox="0 0 43 44" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M21.5003 1.83301C10.6159 1.83301 1.79199 10.8622 1.79199 21.9997C1.79199 33.1372 10.6159 42.1663 21.5003 42.1663C32.3847 42.1663 41.2087 33.1372 41.2087 21.9997C41.2087 10.8622 32.3847 1.83301 21.5003 1.83301ZM25.4545 15.9625C25.7809 15.6167 25.9615 15.1536 25.9574 14.6729C25.9533 14.1922 25.7649 13.7324 25.4327 13.3925C25.1005 13.0526 24.6511 12.8598 24.1814 12.8556C23.7116 12.8514 23.259 13.0362 22.9211 13.3702L15.7544 20.7035C15.4186 21.0473 15.2299 21.5135 15.2299 21.9997C15.2299 22.4858 15.4186 22.952 15.7544 23.2958L22.9211 30.6292C23.259 30.9631 23.7116 31.1479 24.1814 31.1437C24.6511 31.1396 25.1005 30.9468 25.4327 30.6068C25.7649 30.2669 25.9533 29.8071 25.9574 29.3264C25.9615 28.8457 25.7809 28.3826 25.4545 28.0368L19.5546 21.9997L25.4545 15.9625Z" />
        </svg>
      </button>
      <button type="button" className="our-trainings-slider-nav-btn swiper-button-next">
        <svg role="presentation" width={43} height={44} viewBox="0 0 43 44" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M21.4997 1.83301C32.3841 1.83301 41.208 10.8622 41.208 21.9997C41.208 33.1372 32.3841 42.1663 21.4997 42.1663C10.6153 42.1663 1.79134 33.1372 1.79134 21.9997C1.79134 10.8622 10.6153 1.83301 21.4997 1.83301ZM17.5455 15.9625C17.2191 15.6167 17.0385 15.1536 17.0426 14.6729C17.0467 14.1922 17.2351 13.7324 17.5673 13.3925C17.8995 13.0526 18.3489 12.8598 18.8186 12.8556C19.2884 12.8514 19.741 13.0362 20.0789 13.3702L27.2456 20.7035C27.5814 21.0473 27.7701 21.5135 27.7701 21.9997C27.7701 22.4858 27.5814 22.952 27.2456 23.2958L20.0789 30.6292C19.741 30.9631 19.2884 31.1479 18.8186 31.1437C18.3489 31.1396 17.8995 30.9468 17.5673 30.6068C17.2351 30.2669 17.0467 29.8071 17.0426 29.3264C17.0385 28.8457 17.2191 28.3826 17.5455 28.0368L23.4454 21.9997L17.5455 15.9625Z" />
        </svg>
      </button>
    </nav>
  </div>
);

export default Slider;
