import * as React from 'react';
import videojs from 'video.js';
import pl from 'video.js/dist/lang/pl.json';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    videojs.addLanguage('pl', pl);
  }

  componentDidMount() {
    this.player = videojs(this.videoNode, this.props);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <div data-vjs-player>
          <video ref={node => this.videoNode = node} className="video-js"></video>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
