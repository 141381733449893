import * as React from 'react';

import fundings from './../../content/fundings';

class FundingsModal extends React.Component {
  render() {
    if (this.props.active && fundings.voivodeships[this.props.active]) {
      return (
        <div className="funding-modal-overlay">
          <div className="funding-modal" id={this.props.active}>
            <header className="funding-modal-header">
              <h3 className="funding-modal-title">{fundings.voivodeships[this.props.active].displayName}</h3>
              <button type="button" className="funding-modal-close-btn" aria-label="Zamknij" onClick={this.props.handleCloseBtnClick}>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 4L4 20M20 20L4 4L20 20Z" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </header>
            <div className="funding-modal-content">
              <ul className="funding-modal-localizations">
                {fundings.voivodeships[this.props.active].items && fundings.voivodeships[this.props.active].items.map((item, index) => (
                  <li key={index} className="funding-modal-localization">
                    <div className="funding-modal-localization-place">{item.city}</div>
                    <div className="funding-modal-localization-name">{item.name}</div>
                    {item.link && (
                      <a className="funding-modal-localization-link" href={item.link} target="_blank" rel="noreferrer nofollow">
                        <span>Przejdź</span>
                        <svg role="presentation" width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.8334 6.99996L10.8891 9.33329M7.58337 6.99996H12.8334H7.58337ZM12.8334 6.99996L10.8891 4.66663L12.8334 6.99996Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.16663 4.08334V3.01817C8.16662 2.85681 8.13313 2.6972 8.06829 2.54943C8.00345 2.40167 7.90866 2.26896 7.78992 2.1597C7.67117 2.05044 7.53105 1.967 7.37841 1.91465C7.22577 1.86231 7.06393 1.84219 6.90312 1.85559L2.23646 2.24409C1.94482 2.26838 1.67296 2.40138 1.4748 2.61673C1.27664 2.83207 1.16664 3.11402 1.16663 3.40667V10.5933C1.16664 10.886 1.27664 11.1679 1.4748 11.3833C1.67296 11.5986 1.94482 11.7316 2.23646 11.7559L6.90312 12.145C7.06398 12.1584 7.22587 12.1383 7.37855 12.0859C7.53123 12.0335 7.67138 11.95 7.79013 11.8407C7.90889 11.7314 8.00366 11.5986 8.06847 11.4508C8.13327 11.3029 8.1667 11.1433 8.16663 10.9818V9.91667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default FundingsModal;
