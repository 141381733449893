import * as React from 'react';
import PropTypes from 'prop-types';

import TrainingModal from './Training';

import trainings from './../../../content/trainings';

class ModalWrapper extends React.Component {
  static propTypes = {
    innerRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
    type: PropTypes.string.isRequired,
    activeModal: PropTypes.string,
    onCloseButtonClick: PropTypes.func,
    onInterestedButtonClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
    };

    this.modalRef = React.createRef(null);
  }

  componentDidMount() {
    this.modal = this.modalRef.current;
    this.body = document.querySelector('body');
    this.wrapper = this.props.innerRef.current;

    this.setState(state => ({
      ...state,
      activeModal: this.props.activeModal,
    }));

    if (this.state.activeModal) {
      this.open();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeModal !== prevProps.activeModal) {
      this.setState(state => ({
        ...state,
        activeModal: this.props.activeModal,
      }), () => {
        if (this.props.activeModal) {
          this.modal = this.modalRef.current;
          this.open();
        } else {
          this.close();
        }
      });
    }
  }

  handleWindowClick(event) {
    if (this.state.activeModal && this.wrapper) {
      if (this.wrapper.contains(event.target) && !this.modal.contains(event.target)) {
        this.close(() => {
          if (typeof this.props.onCloseButtonClick === `function`) {
            this.props.onCloseButtonClick(event);
          }
        });
      }
    }
  }

  close(callback) {
    if (this.modal && this.wrapper && this.body.style.overflow) {
      this.body.style.setProperty('overflow', '');
      this.modal.style.setProperty('top', '');
      this.wrapper.style.setProperty('opacity', '');
      setTimeout(() => {
        if (this.modal && this.wrapper) {
          this.modal.style.setProperty('display', '');
          this.wrapper.style.setProperty('display', '');
        }
        if (typeof callback === `function`) {
          callback();
        }
      }, 300);
      window.removeEventListener('click', this.handleWindowClick.bind(this));
    }
  }

  open() {
    if (this.modal && this.wrapper) {
      this.body.style.setProperty('overflow', 'hidden');
      this.wrapper.style.setProperty('display', 'block');
      this.modal.style.setProperty('display', 'block');
      setTimeout(() => {
        this.wrapper.style.setProperty('opacity', '1');
        this.modal.style.setProperty('top', '50%');
      }, 300);
      window.addEventListener('click', this.handleWindowClick.bind(this));
    }
  }

  getActiveModalContent() {
    if (!this.props.activeModal)
      return false;

    switch (this.props.type) {
      case 'training':
      default:
        return trainings.find(element => element.id === this.state.activeModal);
    }
  }

  render() {
    const activeModalContent = this.getActiveModalContent();
    const onCloseButtonClick = event => {
      this.close(() => {
        if (typeof this.props.onCloseButtonClick === `function`) {
          this.props.onCloseButtonClick(event);
        }
      });
    };

    return (
      <div className="modal-wrapper" ref={this.props.innerRef}>
        {activeModalContent && (
          <TrainingModal 
            onCloseButtonClick={onCloseButtonClick} 
            onInterestedButtonClick={this.props.onInterestedButtonClick} 
            {...activeModalContent} 
            modalWrapperRef={this.props.innerRef}
            ref={this.modalRef} 
          />
        )}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <ModalWrapper innerRef={ref} {...props} />);
