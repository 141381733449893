/**
 * List of fundings: https://www.google.com/maps/d/u/0/viewer?mid=1LBbvugp62pmATyKiyjcXGO0xCUmdMTvy&ll=53.918450702608965%2C18.77320350238227&z=8
 */

const fundings = {
  voivodeships: {
    slaskie: {
      displayName: `Śląskie`,
      items: [
        {
          city: 'Bielsko-Biała',
          name: 'Bielskie Centrum Przedsiębiorczości ',
          link: 'http://www.bcp.org.pl/',
        },
        {
          city: 'Częstochowa',
          name: 'Agencja Rozwoju Regionalnego w Częstochowie S.A.',
          link: 'https://www.arr.czestochowa.pl/',
        },
        {
          city: 'Dąbrowa Górnicza',
          name: 'Akademia WSB Dąbrowa Górnicza',
          link: 'https://wsb.edu.pl/',
        },
        {
          city: 'Gliwice',
          name: 'Górnośląska Agencja Przedsiębiorczości i Rozwoju sp. z o.o.',
          link: 'https://gapr.pl/',
        },
        {
          city: 'Gliwice',
          name: 'Regionalna Izba Przemysłowo-Handlowa',
          link: 'http://www.riph.com.pl/start',
        },
        {
          city: 'Gliwice',
          name: 'WASKO S.A.',
          link: 'https://www.wasko.pl/',
        },
        {
          city: 'Katowice',
          name: 'Fundusz Górnośląski',
          link: 'https://www.fgsa.pl/',
        },
        {
          city: 'Katowice',
          name: 'Wyższa Szkoła Techniczna w Katowicach',
          link: 'https://www.wst.com.pl/',
        },
        {
          city: 'Zabrze',
          name: 'Centrum Szkoleniowo-Doradcze dr Piotr Kurnicki sp.k. ',
          link: 'http://www.dr-kurnicki.eu/',
        },
        {
          city: 'Zabrze',
          name: 'Podmiotowy System Finansowania dla przedsiębiorstw OSI',
          link: 'http://www.bari-psf.eu/index.php',
        },
        {
          city: 'Żywiec',
          name: 'Akademia WSB Wydział Zamiejscowy w Żywcu',
          link: 'https://wsb.edu.pl/zywiec',
        },
      ],
    },
    zachodniopomorskie: {
      displayName: `Zachodnio-pomorskie`,
      items: [
        {
          city: 'Koszalin',
          name: 'Koszalińska Agencja Rozwoju Regionalnego Spółka Akcyjna',
          link: 'https://karrsa.eu/',
        },
        {
          city: 'Szczecin',
          name: 'Koszalińska Agencja Rozwoju Regionalnego Spółka Akcyjna',
          link: 'https://karrsa.eu/',
        },
        {
          city: 'Szczecin',
          name: 'Polska Fundacja Przedsiębiorczości',
          link: 'https://www.pfp.com.pl/',
        },
        {
          city: 'Szczecin',
          name: 'Zachodniopomorska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://www.zarr.com.pl/',
        },
        {
          city: 'Wałcz',
          name: 'Polska Fundacja Przedsiębiorczości',
          link: 'https://www.pfp.com.pl/',
        },
      ],
    },
    wielkopolskie: {
      displayName: `Wielkopolskie`,
      items: [
        {
          city: 'Kalisz',
          name: 'KALISKI INKUBATOR PRZEDSIĘBIORCZOŚCI',
          link: 'https://www.inkubator.kalisz.pl/',
        },
        {
          city: 'Konin',
          name: 'AGENCJA ROZWOJU REGIONALNEGO',
          link: 'http://arrkonin.org.pl/',
        },
        {
          city: 'Piła',
          name: 'Fundusz Rozwoju i Promocji Województwa Wielkopolskiego SA',
          link: 'http://www.fripww.pl/',
        },
        {
          city: 'Poznań',
          name: 'Fundusz Rozwoju i Promocji Województwa Wielkopolskiego SA',
          link: 'http://www.fripww.pl/',
        },
        {
          city: 'Poznań',
          name: 'STOWARZYSZENIE NA RZECZ SPÓŁDZIELNI SOCJALNYCH',
          link: 'http://spoldzielnie.org/',
        },
        {
          city: 'Poznań',
          name: 'WIELKOPOLSKA AGENCJA ROZWOJU PRZEDSIĘBIORCZOŚCI',
          link: 'http://warp.org.pl/',
        },
        {
          city: 'Poznań',
          name: 'ZAKŁAD DOSKONALENIA ZAWODOWEGO',
          link: 'http://zdz.poznan.pl/',
        },
      ],
    },
    'warminsko-mazurskie': {
      displayName: `Warmińsko-mazurskie`,
      items: [
        {
          city: 'Olsztyn',
          name: 'Techpal Sp. z o.o.',
          link: 'http://techpal.com.pl/',
        },
        {
          city: 'Olsztyn',
          name: 'Warmińsko-Mazurski Związek Pracodawców Prywatnych',
          link: 'http://www.wmzpp.org/',
        },
        {
          city: 'Pasłęk',
          name: 'Stowarzyszenie Centrum Rozwoju Ekonomicznego Pasłęka',
          link: 'https://screp.pl/',
        },
      ],
    },
    'kujawsko-pomorskie': {
      displayName: `Kujawsko-pomorskie`,
      items: [
        {
          city: 'Toruń',
          name: 'Toruńska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://www.tarr.org.pl/strona-glowna',
        },
      ],
    },
    swietokrzyskie: {
      displayName: `Świętokrzyskie`,
      items: [
        {
          city: 'Kielce',
          name: 'Staropolska Izba Przemysłowo - Handlowa',
          link: 'https://siph.com.pl/',
        },
        {
          city: 'Kielce',
          name: 'Świętokrzyskie Centrum Innowacji i Transferu Technologii Sp. z o.o.',
          link: 'https://www.it.kielce.pl/',
        },
        {
          city: 'Sandomierz',
          name: 'Ośrodek Promowania i Wspierania Przedsiębiorczości Rolnej',
          link: 'https://www.opiwpr.org.pl/',
        },
      ],
    },
    dolnoslaskie: {
      displayName: `Dolnośląskie`,
      items: [
        {
          city: 'Jelenia Góra',
          name: 'Karkonoska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://karr.pl/',
        },
        {
          city: 'Legnica',
          name: 'Agencja Rozwoju Regionalnego ARLEG S.A.',
          link: 'https://arleg.eu/',
        },
        {
          city: 'Nowa Ruda',
          name: 'Agencja Rozwoju Regionalnego Agroreg S.A.',
          link: 'https://www.agroreg.com.pl/',
        },
        {
          city: 'Szczawno-Zdrój',
          name: 'Dolnośląska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://www.darr.pl/',
        },
        {
          city: 'Wrocław',
          name: 'Dolnośląska Agencja Współpracy Gospodarczej Sp. z o.o.',
          link: 'https://dawg.pl/',
        },
        {
          city: 'Wrocław',
          name: 'Dolnośląski Park Innowacji i Nauki S.A.',
          link: 'https://dpin.pl/',
        },
        {
          city: 'Wrocław',
          name: 'Wrocławska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://www.warr.pl/',
        },
      ],
    },
    podlaskie: {
      displayName: `Podlaskie`,
      items: [
        {
          city: 'Białystok',
          name: 'Polskie Centrum Edukacji i Analiz ORDO',
          link: 'http://ordo.info.pl/kontakt/',
        },
        {
          city: 'Łomża',
          name: 'Łomżyńska Rada Federacji Stowarzyszeń Naukowo - Technicznych NOT',
          link: 'http://notlomza.pl/',
        },
        {
          city: 'Suwałki',
          name: 'Stowarzyszenie Euroregion Niemen',
          link: 'https://niemen.org.pl/',
        },
      ],
    },
    mazowieckie: {
      displayName: `Mazowieckie`,
      items: [
        {
          city: 'Warszawa',
          name: 'Krajowa Izba Gospodarcza',
          link: 'https://kig.pl/',
        },
        {
          city: 'Warszawa',
          name: 'MDDP Akademia Biznesu Sp. z o.o.',
          link: 'https://www.akademiamddp.pl/',
        },
        {
          city: 'Warszawa',
          name: 'Polska Izba Firm Szkoleniowych',
          link: 'https://pifs.org.pl/',
        },
        {
          city: 'Warszawa',
          name: 'ProcessTeam Sp. z o.o.',
          link: 'https://processteam.pl/',
        },
      ],
    },
    podkarpackie: {
      displayName: `Podkarpackie`,
      items: [
        {
          city: 'Krosno',
          name: 'Podkarpacka Izba Gospodarcza',
          link: 'http://www.pigkrosno.pl/',
        },
        {
          city: 'Mielec',
          name: 'Agencja Rozwoju Regionalnego „MARR” S. A.',
          link: 'https://www.marr.com.pl/',
        },
        {
          city: 'Przemyśl',
          name: 'Przemyska Agencja Rozwoju Regionalnego S.A.',
          link: 'http://parr.pl/',
        },
        {
          city: 'Rzeszów',
          name: 'Rzeszowska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://rarr.rzeszow.pl/',
        },
        {
          city: 'Świlcza',
          name: 'Centrum Doradztwa Gospodarczego Sp. z o. o.',
          link: 'https://www.cdgpolska.pl/',
        },
      ],
    },
    opolskie: {
      displayName: `Opolskie`,
      items: [
        {
          city: 'Opole',
          name: 'Opolskie Centrum Rozwoju Gospodarki',
          link: 'https://ocrg.opolskie.pl/',
        },
        {
          city: 'Opole',
          name: 'Opolskie Centrum Rozwoju Gospodarki',
          link: 'https://ocrg.opolskie.pl/',
        },
      ],
    },
    lodzkie: {
      displayName: `Łódzkie`,
      items: [
        {
          city: 'Łódź',
          name: 'HRP Group sp. z o.o.',
          link: 'https://hrp.com.pl/',
        },
        {
          city: 'Łódź',
          name: 'KRAJOWE CENTRUM PRACY Sp. z o.o.',
          link: 'http://www.krajowecentrumpracy.pl/',
        },
        {
          city: 'Łódź',
          name: 'Łódzka Iza Przemysłowo – Handlowa',
          link: 'https://www.izba.lodz.pl/',
        },
        {
          city: 'Łódź',
          name: 'Łódzka Specjalna Strefa Ekonomiczna S.A',
          link: 'https://sse.lodz.pl/',
        },
      ],
    },
    malopolskie: {
      displayName: `Małopolskie`,
      items: [
        {
          city: 'Kraków',
          name: 'Małopolska Agencja Rozwoju Regionalnego S.A.',
          link: 'https://www.marr.pl/',
        },
        {
          city: 'Kraków',
          name: 'Małopolskie Centrum Przedsiębiorczości (MCP)',
          link: 'https://www.mcp.malopolska.pl/',
        },
        {
          city: 'Nowy Sącz',
          name: 'Centrum Zespołów Analityczno Strategicznych Sp. z o.o.',
          link: 'http://www.czas.sacz.pl/',
        },
        {
          city: 'Nowy Sącz',
          name: 'Nowosądecki Inkubator Przedsiębiorczości',
          link: 'http://www.inkubator.nowysacz.pl/',
        },
        {
          city: 'Nowy Sącz',
          name: 'Sądecka Izba Gospodarcza',
          link: 'https://www.sig.org.pl/',
        },
        {
          city: 'Oświęcim',
          name: 'Centrum Biznesu Małopolski Zachodniej Sp. z o. o.',
          link: 'https://cbmz.pl/',
        },
        {
          city: 'Oświęcim',
          name: 'Stowarzyszenie na Rzecz Szkoły Zarządzania i Handlu w Oświęcimiu',
          link: 'http://szih.pl/',
        },
        {
          city: 'Rabka-Zdrój',
          name: 'Fundacja Rozwoju Regionu Rabka',
          link: 'http://frrr.pl/',
        },
        {
          city: 'Rabka-Zdrój',
          name: 'Fundacja Rozwoju Regionu Rabka',
          link: 'http://frrr.pl/',
        },
        {
          city: 'Wadowice',
          name: 'Oświata i Biznes Sp. z o.o.',
          link: 'http://www.oswiata.slask.pl/firma,c,1',
        },
        {
          city: 'Zakopane',
          name: 'Centrum Zespołów Analityczno Strategicznych Sp. z o.o.',
          link: 'http://www.czas.sacz.pl/',
        },
      ],
    },
    lubuskie: {
      displayName: `Lubuskie`,
      items: [
        {
          city: 'Gorzów Wielkopolski',
          name: 'Zachodnia Izba Przemysłowo-Handlową w Gorzowie Wielkopolskim',
          link: 'https://www.ziph.pl/',
        },
        {
          city: 'Zielona Góra',
          name: 'Agencja Rozwoju Regionalnego Spółka Akcyjna',
          link: 'http://www.region.zgora.pl/',
        },
      ],
    },
    lubelskie: {
      displayName: `Lubelskie`,
      items: [
        {
          city: 'Chełm',
          name: 'Stowarzyszenie Rozwoju Aktywności Społecznej „Triada”',
          link: 'http://triada-chelm.pl/',
        },
        {
          city: 'Lublin',
          name: 'Pracodawcy Lubelszczyzny "Lewiatan"',
        },
        {
          city: 'Lublin',
          name: 'Wschodnia Agencja Rozwoju sp. z o.o.,',
          link: 'http://warwschod.pl/pl/',
        },
        {
          city: 'Puławy',
          name: 'INSTYTUT ROZWOJU I INNOWACJI EURO-KONSULT SP. Z O. O.',
          link: 'http://www.euro-konsult.pl/',
        },
      ],
    },
  },
};

export default fundings;
