const collaborationSlidesLeft = [
  { image: `slides/3.jpg`, alt: `` },
  { image: `slides/3-1.jpg`, alt: `` },
  { image: `slides/3-2.jpg`, alt: `` },
  { image: `slides/3-3.jpg`, alt: `` },
  { image: `slides/3-4.jpg`, alt: `` },
  { image: `slides/3-5.jpg`, alt: `` },
  { image: `slides/3-6.jpg`, alt: `` },
];

const collaborationSlidesRight = [
  { image: `slides/21.jpg`, alt: `` },
  { image: `slides/22.jpg`, alt: `` },
  { image: `slides/23.jpg`, alt: `` },
  { image: `slides/24.jpg`, alt: `` },
  { image: `slides/25.jpg`, alt: `` },
];

const collaborationSlidesRightOptional = [
  { image: `slides/11.jpg`, alt: `` },
  { image: `slides/12.jpg`, alt: `` },
  { image: `slides/13.jpg`, alt: `` },
  { image: `slides/14.jpg`, alt: `` },
  { image: `slides/15.jpg`, alt: `` },
  { image: `slides/16.jpg`, alt: `` },
  { image: `slides/17.jpg`, alt: `` },
  { image: `slides/18.jpg`, alt: `` },
];

export { collaborationSlidesLeft, collaborationSlidesRight, collaborationSlidesRightOptional };
