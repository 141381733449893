import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

class TrainingModal extends React.Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    coach: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.node,
    }),
    rating: PropTypes.number,
    id: PropTypes.string.isRequired,
    reviews: PropTypes.number,
    description: PropTypes.node,
    iconAlt: PropTypes.string,
    place: PropTypes.shape({
      image: PropTypes.string,
      alt: PropTypes.string,
    }),
    onCloseButtonClick: PropTypes.func.isRequired,
    onInterestedButtonClick: PropTypes.func.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
  };

  static defaultProps = {
    rating: 0.0,
    reviews: 0,
  };

  render() {
    const iconAlt = this.props.iconAlt ? this.props.iconAlt : this.props.coach.name;

    return (
      <StaticQuery 
        query={graphql`
          query TrainingModalQuery {
            coaches: allFile(filter: {relativeDirectory: {eq: "coaches"}}) {
              edges {
                node {
                  relativePath
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      placeholder: BLURRED,
                      quality: 100,
                      width: 120
                    )
                  }
                }
              }
            }

            places: allFile(filter: {relativeDirectory: {eq: "trainings/places"}}) {
              edges {
                node {
                  relativePath
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      placeholder: BLURRED,
                      quality: 100,
                      width: 538
                    )
                  }
                }
              }
            }
          }
        `} 
        render={data => {
          const coachImage = data.coaches.edges.find(item => item.node.relativePath === this.props.coach?.image);
          const placeImage = data.places.edges.find(item => item.node.relativePath === this.props.place?.image);

          return (
            <article className="training-modal" id={`training-${this.props.id}`} ref={this.props.innerRef}>
              <header className="training-modal-header">
                <div className="training-modal-header-close">
                  <button type="button" className="training-modal-header-close-btn" aria-label="Zamknij" onClick={this.props.onCloseButtonClick}>
                    <svg role="presentation" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M26.6668 5.33325L5.3335 26.6666M26.6668 26.6666L5.3335 5.33325L26.6668 26.6666Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
                <div className="training-modal-header-meta">
                  <div className="training-modal-header-image">
                    <GatsbyImage image={getImage(coachImage.node)} alt={iconAlt} />
                  </div>
                  <div className="training-modal-header-description">
                    <div className="training-modal-header-description-content">
                      <div className="training-modal-header-category">{this.props.category}</div>
                      <div className="training-modal-header-author">{this.props.coach.name}</div>
                      <h2 className="training-modal-header-title">{this.props.title}</h2>
                    </div>
                    <div className="training-modal-header-rating">
                      <div className="training-modal-header-rating-stars">
                        <span><span className="sr-only">Ocena {this.props.rating} na 5</span><span aria-hidden="true">{this.props.rating}</span></span>
                        <svg role="presentation" width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.9969 2.8525C12.3436 1.93816 13.6555 1.93816 14.0032 2.8525L16.2458 9.06433C16.4028 9.476 16.8026 9.75008 17.2489 9.75008H22.7598C23.7782 9.75008 24.2223 11.0176 23.4218 11.6383L19.5001 15.1667C19.3244 15.3018 19.196 15.489 19.1334 15.7015C19.0707 15.9141 19.077 16.141 19.1512 16.3497L20.5834 22.4197C20.9322 23.3947 19.8034 24.2321 18.9498 23.6319L13.623 20.2519C13.4406 20.1237 13.223 20.0549 13.0001 20.0549C12.7771 20.0549 12.5596 20.1237 12.3772 20.2519L7.05042 23.6319C6.19783 24.2321 5.06792 23.3936 5.41675 22.4197L6.84892 16.3497C6.92321 16.141 6.92947 15.9141 6.8668 15.7015C6.80413 15.489 6.67575 15.3018 6.50008 15.1667L2.57842 11.6383C1.77675 11.0176 2.22308 9.75008 3.23925 9.75008H8.75017C8.96715 9.7508 9.17924 9.68563 9.35837 9.56318C9.5375 9.44072 9.67523 9.26677 9.75333 9.06433L11.9958 2.8525H11.9969Z" fill="#D5B195" stroke="#D5B195" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className="training-modal-header-rating-reviews">
                        {`${this.props.reviews} ${this.props.reviews === 1 ? `recenzja` : `recenzji`}`}
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div className="training-modal-content">
                <button type="button" className="training-modal-content-contact-btn training-modal-content-contact-btn-top" onClick={this.props.onInterestedButtonClick}>
                  Jestem zainteresowany
                </button>

                <section className="training-modal-section">
                  <h3 className="training-modal-section-title">Opis trenera</h3>
                  {this.props.coach.description}
                </section>
                <section className="training-modal-section">
                  <h3 className="training-modal-section-title">Opis szkolenia</h3>
                  {this.props.description}
                </section>

                <div className="training-modal-content-rating">
                  <div className="training-modal-content-rating-stars">
                    <span><span className="sr-only">Ocena {this.props.rating} na 5</span><span aria-hidden="true">{this.props.rating}</span></span>
                    <svg role="presentation" width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.9969 2.8525C12.3436 1.93816 13.6555 1.93816 14.0032 2.8525L16.2458 9.06433C16.4028 9.476 16.8026 9.75008 17.2489 9.75008H22.7598C23.7782 9.75008 24.2223 11.0176 23.4218 11.6383L19.5001 15.1667C19.3244 15.3018 19.196 15.489 19.1334 15.7015C19.0707 15.9141 19.077 16.141 19.1512 16.3497L20.5834 22.4197C20.9322 23.3947 19.8034 24.2321 18.9498 23.6319L13.623 20.2519C13.4406 20.1237 13.223 20.0549 13.0001 20.0549C12.7771 20.0549 12.5596 20.1237 12.3772 20.2519L7.05042 23.6319C6.19783 24.2321 5.06792 23.3936 5.41675 22.4197L6.84892 16.3497C6.92321 16.141 6.92947 15.9141 6.8668 15.7015C6.80413 15.489 6.67575 15.3018 6.50008 15.1667L2.57842 11.6383C1.77675 11.0176 2.22308 9.75008 3.23925 9.75008H8.75017C8.96715 9.7508 9.17924 9.68563 9.35837 9.56318C9.5375 9.44072 9.67523 9.26677 9.75333 9.06433L11.9958 2.8525H11.9969Z" fill="#D5B195" stroke="#D5B195" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div className="training-modal-content-rating-reviews">
                    {`${this.props.reviews} ${this.props.reviews === 1 ? `recenzja` : `recenzji`}`}
                  </div>
                </div>

                <button type="button" className="training-modal-content-contact-btn" onClick={this.props.onInterestedButtonClick}>
                  Jestem zainteresowany
                </button>

                {this.props.place && placeImage && (
                  <GatsbyImage className="training-modal-content-place-image" image={getImage(placeImage.node)} alt={this.props.place.alt ? this.props.place.alt : ``} />
                )}
              </div>
            </article>
          );
        }} 
      />
    );
  }
}

export default React.forwardRef((props, ref) => <TrainingModal {...props} innerRef={ref} />);
