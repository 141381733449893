import * as React from 'react';

import VideoPlayer from './../VideoPlayer';

const sources = [
  {
    src: 'https://static.vss.com.pl/web/vss.mp4',
    type: 'video/mp4',
  },
];

const videoOptions = {
  autoplay: true,
  controls: true,
  muted: true,
  playsinline: true,
  sources,
  preload: 'auto',
  fluid: false,
  aspectRatio: '16:9',
  language: 'pl',
};

class Video extends React.Component {
  render() {
    return (
      <VideoPlayer className="hero-video" {...videoOptions} />
    );
  }
}

export default Video;
