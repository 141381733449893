import * as React from 'react';

const trainings = [
  // {
  //   icon: Training1,
  //   category: `Techniczne`,
  //   title: `Optymalizacja procesów linii montażowej`,
  //   sliderTitle: `Optymalizacja procesów linii montażowej`,
  //   coach: {
  //     name: `Karol Jucha`,
  //     description: <>
  //       <p>Absolwent włoskiej szkoły projektowania i konstrukcji obuwia Arsutoria oraz kursu "Projektowanie i konstrukcja obuwia" - Tadeusz Ziajka. 6 letnie doświadczenie na stanowiskach konstruktorskich w takich firmach jak Gino Rossi S.A. Właściciel biura projektowego oraz własnej marki obuwniczo-odzieżowej premium: www.e-hers.com. Przeprowadził kilka tysięcy godzin szkoleń i doradztwa z zakresu produkcji, przygotowania produkcji, reorganizacji działów, wdrażania technologii informatycznych w firmach obuwniczych. Autor bloga o tematyce obuwniczej.Pomysłodawca i prekursor wdrożenia technologii AR w procesy w firmach produkcyjnych.</p>
  //     </>,
  //   },
  //   rating: 4.6,
  //   id: `1`,
  //   reviews: 35,
  //   description: <>
  //     <p>Celem usługi jest zwiększenie efektywności linii produkcyjnej w firmie obuwniczej. Zakładany rezultat to 3% wzrostu efektywności: Wskaźnik weryfikujący = dzienna wielkość produkcji / liczba pracowników.
  //     <br />
  //     <br />Analiza całościowego modelu pracy linii montażowej
  //     <br />Wspólna analiza poszczególnych procesów produkcyjnych
  //     <br />Analiza odstępstw od prawidłowego modelu produkcji, omówienie ich w kontekście różnicy w efektach.
  //     <br />Sposoby kontroli wewnątrz operacyjnej
  //     <br />Kontrola międzyoperacyjna
  //     <br />Sposoby kontroli gotowego produktu
  //     <br />Utworzenie docelowego standardu jakości dla finalnych produktów przedsiębiorstwa
  //     <br />Omówienie poszczególnych procesów w kontekście zakładanych efektów
  //     <br />Skrócenie cyklu produkcyjnego, wprowadzanie korekt na bazie poszczególnych analiz.
  //     <br />Optymalizacja procesów
  //     <br />Decentralizacja kierownictwa dla małej linii produkcyjnej.
  //     <br />Planowanie dziennej produkcji w kontekście posiadanych zasobów
  //     <br />Poprawa efektywności pracy grupowej.
  //     <br />Utworzenie planu reorganizacji stanowisk
  //     <br />Wdrożenie planu</p>
  //   </>,
  // },
  {
    icon: `trainings/time-managament.png`,
    category: `Zarządzanie czasem`,
    title: <>Work life balance, czyli jak znaleźć spokój w pędzącym świecie? <br />Warsztat pracy nad równowagą w swoim życiu.</>,
    sliderTitle: `Work life balance`,
    sliderSubtitle: `czyli jak znaleźć spokój w pędzącym świecie?`,
    coverImage: `trainings/covers/work-life-balance.jpg`,
    coach: {
      name: `Beata Szklanny`,
      image: `coaches/beata-szklanny-work-life-balance.jpg`,
      description: <>
        <p>Beata Szklanny to psycholog, trener biznesu, akredytowany Coach ICF (ACC), licencjonowany konsultant MTQ48, praktyk metody Points of You oraz Racjonalnej Terapii Zachowania. Facylitator Job Craftingu – metody pozwalającej odnaleźć satysfakcję oraz sens swojej pracy. <br />
        Od roku 2014 swoją wiedzę oraz doświadczenie psychologiczne wykorzystuje w biznesie, gdzie implementuje m.in. skuteczne metody zarządzania swoim czasem, stresem oraz emocjami, uczy ludzi jak duże znaczenie mają ich przekonania oraz samoocena. Doradza w zakresie budowania zespołów, dobrej komunikacji i asertywności. <br />
        Pomaga w lepszej organizacji czasu oraz dzieli się skutecznymi poradami, jak radzić sobie ze stresem i budować odporność psychiczną. <br />
        W swojej pracy wspiera uczestników szkolenia w budowaniu spokoju oraz dobrym zarządzaniu swoją energią. Korzysta z mądrości zachodniej Psychologii oraz wschodniej Ajurwedy, co pozwala spojrzeć na człowieka holistycznie.</p>
      </>,
    },
    rating: 4.7,
    id: `work-life-balance`,
    reviews: 11,
    description: <>
      <p>Warsztat przygotuje uczestnika do samodzielnego wspierania się, dzięki poznaniu narzędzi, które pozwolą radzić sobie ze stresem oraz zadbać o swoją równowagę.</p>
      <p>Po zakończeniu warsztatu nauczysz się dokonywać samodzielnej diagnozy poziomu stresu, jakiego doświadczasz oraz stanu swojej równowagi. Będziesz wiedział, jakie narzędzia dobrać do odpowiedniej sytuacji w której się znalazłeś. <br />
      Szkolenie to pomoże Ci zdecydować z jakiego źródła możesz pozyskać dla siebie energię, aby poradzić sobie z wyzwaniami w pracy oraz życiu prywatnym. Dowiesz się, jak stworzyć plan wspierania swojej równowagi w oparciu o indywidualną sytuację życiową.</p>
      <p>Całość odbędzie się w magicznym mieście aniołów, czyli bardzo urokliwej miejscowości, jaką jest Lanckorona. Dodatkową atrakcją szkolenia będą zajęcia jogi.</p>
      <p>Czas trwania: 16 godzin <br />Data: 06. - 07.11.2021 r. 
      <br />Miejsce: Dwór w Lanckoronie</p>
    </>,
    place: {
      image: `trainings/places/dwor-lanckorona.jpg`,
      alt: `Dwór w Lanckoronie`,
    },
  },
  {
    icon: `trainings/business-managament.png`,
    category: `Zarządzanie`,
    title: `LEADERSHIP COACHING - odpowiedź na wyzwania XXI wieku`,
    sliderTitle: `LEADERSHIP COACHING`,
    sliderSubtitle: `odpowiedź na wyzwania XXI wieku`,
    coverImage: `trainings/covers/leadership.jpg`,
    coach: {
      name: `Robert Męcik`,
      image: `coaches/robert-zarzadzanie-przedsiebiorstwem.jpg`,
      description: <>
        <p>Robert Męcik to dyplomowany Business &amp; Life Coach, certyfikowany trener Standardu HRD BP. <br />
        Pracował jako przedstawiciel handlowy, Manager ds. rozwoju firmy oraz Dyrektor Sprzedaży w firmie działającej w branży budowlanej. <br />
        Prowadzi i projektuje warsztaty oraz szkolenia z zakresu sprzedaży, zarządzania i kompetencji społecznych. Ponad 2500 godzin jako trener i 304 godziny jako coach. Jest licencjonowanym partnerem Thalento &reg; - narzędzia pomagającego lepiej zrozumieć funkcjonowanie ludzi w organizacji. <br />
        Pomaga osobom, które chcą rozwijać swoje umiejętności i świadomie być managerem własnego życia zawodowego i osobistego. Ceni sobie profesjonalizm, w 100% oddaje się temu co robi, nieustannie doskonaląc swoje umiejętności. Na szkoleniach angażuje uczestników do aktywności i dzielenia się doświadczeniami.</p>
      </>,
    },
    rating: 4.6,
    id: `leadership-coaching`,
    reviews: 35,
    description: <>
      <p>Szkolenie ma na celu przygotowanie uczestnika do efektywnego zarządzania zespołem, bycia liderem we współczesnym świecie. </p>
      <p>Szkolenie to pozwoli Ci poznać techniki niezbędne do tworzenia zespołu. Przedstawiony zostanie schemat komunikacji oraz nauka zadawania pytań i aktywnego słuchania. <br />
      Nauczysz się zasad motywowania oraz skutecznego zarządzania kadrą pracowniczą. Nabędziesz umiejętności  z zakresu skutecznego delegowania, jak również zapoznasz się z wiedzą i umiejętnościami jakie powinien mieć lider, aby budować pewność swojego zespołu.</p>
      <p>Czas trwania: 16 godzin <br />
      Data: 13-14.11.2021 r. <br />
      Miejsce: MOLO Resort</p>
    </>,
    place: {
      image: `trainings/places/molo.jpg`,
      alt: `MOLO Resort`,
    },
  },
  {
    icon: `trainings/sales.png`,
    category: `Sprzedaż`,
    title: `Profesjonalny handlowiec – jak sprzedawać, aby kupowano?`,
    sliderTitle: `Profesjonalny handlowiec`,
    sliderSubtitle: `jak sprzedawać, aby kupowano?`,
    coverImage: `trainings/covers/profesjonalny-handlowiec.jpg`,
    coach: {
      name: `Elżbieta Jachymczak`,
      image: `coaches/elzbieta-jachymczak-profesjonalny-handlowiec.jpg`,
      description: <>
        <p>Elżbieta Jachymczak, przedsiębiorczyni, ekonomista, ambitna i niezależna dziennikarka ekonomiczna, zapraszana w roli prelegentki na najbardziej prestiżowe wydarzenia ekonomiczne i dziennikarskie w Polsce, tj. Forum Ekonomiczne w Krynicy, Forum Mediów i Komunikacji czy Festiwal Rok Kobiet. Wydawca, Redaktor Naczelna portalu <a href="https://business-intelligence.com.pl/" target="_blank" rel="noreferrer nofollow">https://business-intelligence.com.pl/</a> oraz Dwumiesięcznika Business Intelligence, założycielka i pomysłodawczyni Akademii Kobiecej Przedsiębiorczości. Od ponad 16 lat związana z biznesem, dziennikarstwem i edukacją. Z sukcesem prowadzi swoje firmy. Jej działalność ma trwały i widoczny wpływ na polski rynek wydawniczy oraz polską przedsiębiorczość. Jest ekspertką od skutecznego budowania marki osobistej dzięki mediom.</p>
      </>,
    },
    rating: 4.8,
    id: `profesjonalna-sprzedaz`,
    reviews: 20,
    description: <>
      <p>Kurs „Profesjonalny handlowiec – jak sprzedawać, aby kupowano?” daje praktyczne umiejętności w zakresie skutecznej sprzedaży i pracy na stanowisku handlowca. Pozwala na samodzielne prowadzenie działań sprzedażowych i zajmowanie stanowisk w handlu.</p>
      <p>Szkolenie skierowane jest do przedsiębiorców, prowadzących małe i średnie firmy, jak również osób zajmujących się sprzedażą lub myślących o podjęciu pracy w zawodzie sprzedawca/handlowiec. <br />
      Właściciele sklepów internetowych i stacjonarnych znajdą wiele porad i trafnych wskazówek.</p>
      <p>Nauczysz się charakteryzować zasady prawne handlu oraz definiować pojęcia z zakresu prawa ochrony danych osobowych. <br />
      Dowiesz się, dlaczego ważne jest posiadanie umiejętności sprzedażowych,
      poznasz zasady budowania skutecznej sprzedaży. A przede wszystkim nauczysz się, jak w 5 krokach osiągnąć sukces w handlu!
      </p>
      <p>Całość odbędzie się w nowoczesnej i profesjonalnie wyposażonej sali konferencyjnej znajdującej się w obiekcie ParkHotel Łysoń. Jest to nowoczesny 4-gwiazdkowy hotel położony w miejscowości Inwałd.</p>
      <p>Czas trwania: 2 dni, po 8 godzin każdy <br />
      Data: 13-14.11.2021 r. <br />
      Miejsce: Inwałd ParkHotel Łysoń</p>
    </>,
    place: {
      image: `trainings/places/lyson.jpg`,
      alt: `ParkHotel Łysoń`,
    },
  },
  {
    icon: `trainings/marketing.png`,
    category: `Marketing`,
    title: `Marketing Manager – od podstaw do poziomu średniozaawansowanego w 2 dni`,
    sliderTitle: `Marketing Manager`,
    sliderSubtitle: `od podstaw do poziomu średniozaawansowanego w 2 dni`,
    coverImage: `trainings/covers/menadzer.jpg`,
    coach: {
      name: `Łukasz Falba`,
      image: `coaches/lukasz-falba-marketing-manager.jpg`,
      description: <>
        <p>Łukasz Falba jest specjalista ds. digital marketingu oraz współzałożycielem agencji marketingowej 4WebZones, która zajmuje się kompleksową obsługą marketingową dla klientów z całej Europy. Jego specjalizacja to strategia marketingowa dla małych i średnich przedsiębiorstw. Na sali szkoleniowej spędził setki godzin, ucząc niestandardowego podejścia do marketingu, jednocześnie odwołując się do twardej teorii i klasycznych faktów. Wyznaje holistyczne podejście do marketingu, które wdraża u swoich klientów w agencji oraz jako indywidualny konsultant. W trakcie pierwszej fali pandemii współorganizował konferencję online „BAOS Summit”, która na 3 edycjach zgromadziła tysiące przedsiębiorców i gościła wybitnych prelegentów, którzy swoją wiedzą starali się pomóc przedsiębiorcom przetrwać ten trudny okres.</p>
      </>,
    },
    rating: 4.8,
    id: `marketing-manager`,
    reviews: 7,
    description: <>
      <p>Marketing Manager – od podstaw do poziomu średniozaawansowanego w 2 dni – kurs przygotowuje do samodzielnego tworzenia, wdrażania i kontroli pełnego marketingu dla małych i średnich przedsiębiorstw.</p>
      <p>Po ukończeniu szkolenia zdobędziesz praktyczną wiedzę, jak prawidłowo stworzyć pełną strategię marketingową. Dowiesz się, jak identyfikować rodzaje kampanii marketingowych, wdrażać operacyjne procesy marketingowe oraz tworzyć efektywne działy marketingowe. <br />
      Kurs pozwoli Ci płynnie poruszać się po podstawowych narzędziach reklamowych takich jak Facebook Ads, Google Ads, LinkedIn Ads. Zrozumiesz rolę brandingu w obecnym marketingu i będziesz potrafił spersonalizować strategię tą do własnych potrzeb. Po przeprowadzonych działaniach sam będziesz potrafił zmierzyć efektywność działań marketingowych oraz  przeprowadzić podstawowy, kompleksowy audyt marketingowy.</p>
      <p>Całość odbędzie się w jedynym w Polsce resorcie stworzonym z morskich kontenerów oraz pastelowych apartamentów. Niepowtarzalny design i otoczenie charakteryzują SCANDINAVIA RESORT, który znajduje się niespełna 2 km od największego w Polsce parku rozrywki.</p>
      <p>Czas trwania: 2 dni, po 7 godzin każdy <br />
      Data: 24-25.11.2021 r. <br />
      Miejsce: SCANDINAVIA RESORT</p>
    </>,
    place: {
      image: `trainings/places/scandi.jpg`,
      alt: `SCANDINAVIA RESORT`,
    },
  },
  {
    icon: `trainings/personal-efficiency.png`,
    category: `Rozwój osobisty`,
    title: `Efektywność osobista (Bądź zmianą!)`,
    sliderTitle: `Efektywność osobista`,
    sliderSubtitle: `Bądź zmianą!`,
    coverImage: `trainings/covers/efektywnosc-osobista.jpg`,
    coach: {
      name: `Sabina Gagaczowska`,
      image: `coaches/sabina-gagaczowska.jpg`,
      description: <>
        <p>Absolwentka 5-letnich jednolitych studiów magisterskich na kierunku Psychologia, trenerem rozwoju osobistego, trenerką motywacyjną, a także psychoterapeutą Gestalt w trakcie szkolenia, atestowanego przez European Association for Gestalt Therapy. Na co dzień pracuję z klientami indywidualnymi i biznesowym. <br />
        Pomaga w realizowaniu misji życiowej i kreowaniu życia marzeń. Wspiera na drodze do zmiany i osobistego rozwoju. Ponadto motywuję do osiągania wyznaczonych przez siebie celów.</p>
      </>,
    },
    rating: 4.9,
    id: `efektywnosc-osobista`,
    reviews: 20,
    description: <>
      <figure>
        <blockquote>
          <p>Nie chodzi o to, by nadawać priorytety temu, co masz w planie, ale by planować zgodnie z priorytetami</p>
        </blockquote>
        <figcaption>Stephen R. Covey</figcaption>
      </figure>
      <p>Dlaczego efektywność osobista? <br />
      Bycie skutecznym, to osiąganie celu poprzez działanie, które daje oczekiwane rezultaty. Poziom skuteczności jest ściśle połączony z poziomem założonego planu. Nakłady, które poniesiemy aby cel zrealizować, to: czas, finanse, ludzie, narzędzia. Traktując budowanie efektywności osobistej, szczególnie warto zwrócić uwagę na powiązanie z kompetencjami i zasobami.</p>
      <p>Korzyści ze szkolenia: <br />
      Nauczysz się budować swoją skuteczność w oparciu o zasoby, jakie posiadasz. <br />
      Zdobędziesz wiedzę na temat tego, jak wyznaczać cele w oparciu o misję i wizję. <br />
      Uświadomisz sobie, jaki wpływ na zwiększenie własnej efektywności ma otwartość na zmiany. <br />
      Dowiesz się, jak wykorzystać i rozwijać swoją kreatywność, aby znaleźć szybsze i lepsze rozwiązanie problemu. <br />
      Zadbasz bardziej o siebie, realizując w pierwszej kolejności swoje cele, nie ulegając naciskom, aby robić coś dla celów innych osób.</p>
    </>,
  },
  {
    icon: `trainings/sales.png`,
    category: `Sprzedaż`,
    title: `Jak zwiększyć sprzedaż obuwia i odzieży, dzięki wiedzy o trendach w modzie i nowych oczekiwań klientów?`,
    sliderTitle: `Jak zwiększyć sprzedaż obuwia i odzieży`,
    coverImage: `trainings/covers/odziez-i-obuwie.jpg`,
    coach: {
      name: `Jerzy Osika`,
      image: `coaches/jerzy-osika.jpg`,
      description: <>
        <p>Jerzy Osika - Ekspert ds. sprzedaży, biznesu mody, trendów konsumenckich i handlu. <br />
        Od ponad 20 lat pomaga firmom z branży mody jako szkoleniowiec, wykładowca, doradca, organizator wydarzeń edukacyjnych, autor fachowych publikacji i wydawca. 
        Szkoli i doradza firmom w zakresie: sprzedaży, trendów konsumenckich, innowacyjnego handlu zarządzania, umiejętności osobistych, pozyskiwania klientów na rynkach zagranicznych. Wykładowca na targach i konferencjach (m.in. w Toronto, Las Vegas, Hongkongu, Mumbaju, Frankfurcie nad Menem, Lipsku, Walencji, Porto, Kijowie).</p>
        <p>Reprezentant Polski w Radzie Ekspertów międzynarodowego konkursu Euroshop Retail Design Award (Düsseldorf). Przedstawiciel w Polsce światowej agencji trendów Fashion Snoops z Nowego Jorku.</p>
      </>,
    },
    rating: 4.7,
    id: `trendy-modowe`,
    reviews: 26,
    description: <>
      <p>Szkolenie ma pomóc firmom oferującym obuwie i odzież w dopasowaniu oferty do trendów w modzie, zmian w zachowaniach zakupowych konsumentów i trendów w handlu. <br />
      Program szkolenia obejmuje trendy na rok 2022, zarówno w modzie damskiej, jak i męskiej. W każdym z trendów, uczestnicy poznają tendencje w modzie odzieżowej oraz wynikające z nich trendy w obuwiu. Zdobędą wiedzę o inspiracjach, wiodących fasonach, kolorystyce i deseniach. Dzięki praktycznym ćwiczeniom uczestnicy utrwalą zdobytą wiedzę o trendach i zyskają umiejętności jej zastosowania w praktyce, w tym poprzez dopasowanie swoich ofert do różnych grup docelowych, będących adresatami poszczególnych trendów. Zdobyta wiedza i umiejętności przydadzą się firmom oferującym swoje produkty w Polsce, jak i na rynkach zagranicznych. Dla zwiększenia efektywności działań sprzedażowych, program obejmuje też wiedzę (potwierdzoną m.in. wynikami badań rynkowych) w zakresie zmian w zachowaniach zakupowych konsumentów (będących m.in. skutkiem pandemii). </p>
    </>,
  },
  {
    icon: `trainings/time-managament.png`,
    category: `Zarządzanie czasem`,
    title: `Zarządzanie sobą w czasie`,
    sliderTitle: `Zarządzanie sobą w czasie`,
    coverImage: `trainings/covers/zarzadzanie-soba-w-czasie.jpg`,
    coach: {
      name: `Ewa Brzoza`,
      image: `coaches/ewa-brzoza.jpg`,
      description: <>
        <p>Ewa Brzoza - Pedagog, doradca rozwoju osobistego (life i business-coach), konsultant ds. rekrutacji i
        szkoleń, specjalista w zakresie zarządzania kadrami, trener, wolontariusz. W zawodzie
        aktywnie pracuje od 23 lat. Stale pogłębia swoją wiedzę i doskonali praktykę. 
        Twórca wielu programów szkoleniowych i artykułów do prasy specjalistycznej dotyczących
        komunikowania się, rozwiązywania konfliktów, sztuki sprzedaży, asertywności, twórczości,
        motywacji, negocjacji, budowania zespołu i coachingu. Specjalizuje się w organizowaniu i
        prowadzeniu szkoleń m.in. z zakresu: „Zarządzania sobą w czasie”, „Kierowania
        motywującego”, „Negocjacji handlowych”, „Technik sprzedaży”, „Autoprezentacji”, „Mowy
        ciała”, „Radzenia sobie ze stresem” oraz szkoleń integracyjnych, gdzie oprócz aktywnego
        wypoczynku uczestnicy wspólnie z trenerami pracują nad budowaniem zespołu i uczestniczą
        w warsztatach z wybranych tematów szkoleń</p>
      </>,
    },
    rating: 4.5,
    id: `zarzadzanie-soba-w-czasie`,
    reviews: 30,
    description: <>
      <p>Zarządzanie sobą i zadaniami w czasie, umiejętność skupienia się na priorytetach, a także terminowość ich realizacji to jedna z najbardziej pożądanych kompetencji we współczesnych firmach. Brak umiejętności organizacji czasu i znajomości metod zwiększających efektywność powoduje piętrzenie się zadań, niedotrzymywanie terminów i ciągłe odkładanie pracy na później, co w konsekwencji podnosi poziom stresu u pracowników, wzmaga frustrację, a dla firmy przekłada się na straty finansowe.</p>
      <p>Szkolenie prowadzone w formie warsztatu, w czasie, którego uczestnicy poznane metody i techniki przekładają na konkretne sposoby działania i decydują, w jaki sposób wykorzystają je w swoim miejscu pracy.</p>
      <p>Warsztat prowadzony jest przez trenera biznesu – ekspertkę szkoleń z zakresu zarządzania sobą w czasie i osiągania maksymalnej produktywności</p>
    </>,
  },
  {
    icon: `trainings/business-managament.png`,
    category: `Zarządzanie`,
    title: `Teamworking, zarządzanie i praca w zespole`,
    sliderTitle: `Teamworking`,
    sliderSubtitle: `zarządzanie i praca w zespole`,
    coverImage: `trainings/covers/teamwork.jpg`,
    coach: {
      name: `Robert Męcik `,
      image: `coaches/robert-zarzadzanie-przedsiebiorstwem.jpg`,
      description: <>
        <p>Robert Męcik to dyplomowany Business &amp; Life Coach, certyfikowany trener Standardu HRD BP. <br />
        Prowadzi i projektuje warsztaty oraz szkolenia z zakresu sprzedaży, zarządzania i kompetencji społecznych. Ponad 2500 godzin jako trener i 304 godziny jako coach. Jest licencjonowanym partnerem Thalento ® - narzędzia pomagającego lepiej zrozumieć funkcjonowanie ludzi w organizacji. <br />
        Pomaga osobom, które chcą rozwijać swoje umiejętności i świadomie być managerem własnego życia zawodowego i osobistego. Ceni sobie profesjonalizm, w 100% oddaje się temu co robi, nieustannie doskonaląc swoje umiejętności. Na szkoleniach angażuje uczestników do aktywności i dzielenia się doświadczeniami.</p>
      </>,
    },
    rating: 4.6,
    id: `teamworking`,
    reviews: 17,
    description: <>
      <p>Jedną z podstawowych funkcji lidera w grupie jest motywowanie pracowników. Podstawą do rozpoczęcia tych działań jest znajomość potrzeb pracowników oraz dopasowanie działań do ich oczekiwań i umiejętności.</p>
      <p>Szkolenie pozwoli rozwinąć kompetencje w obszarze: organizacja współpracy, role zespołowe, podejmowanie decyzji oraz praca w zespole pod presją czasu.</p>
      <p>Udział w warsztatach pozwoli na wzmocnienie współpracy w zespole, poprzez poznanie zasad funkcjonowania zespołu, elementów jego budowania, tworzenie właściwych relacji i komunikacji w miejscu pracy, udzielanie informacji zwrotnych.</p>
    </>,
  },
];

export default trainings;
