import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Card = ({ id, coverImage, sliderTitle, sliderSubtitle, category, rating, reviews, onButtonClick }) => (
  <StaticQuery 
    query={graphql`
      query TrainingCardQuery {
        covers: allFile(filter: {relativeDirectory: {eq: "trainings/covers"}}) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  width: 520
                )
              }
            }
          }
        }
      }
    `}
    render={({ covers }) => {
      const cover = covers.edges.find(item => item.node.relativePath === coverImage);

      return (
        <article className="training-card training-card-slide">
          <div className="training-card-cover-image">
            {cover && (
              <GatsbyImage image={getImage(cover.node)} alt={sliderTitle} />
            )}
          </div>
          <div className="training-card-description">
            <div className="training-card-meta">
              <div className="training-card-category">
                <span className="sr-only">Kategoria: </span>
                {category}
              </div>
              <div className="training-card-rating-reviews">
                <div className="training-card-rating">
                  <strong><span className="sr-only">Ocena: </span>{rating}</strong>
                  <svg role="presentation" width={13} height={14} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.9984 1.92588C6.17173 1.46872 6.82769 1.46872 7.00156 1.92588L8.12281 5.0318C8.20136 5.23763 8.40123 5.37467 8.6244 5.37467H11.3799C11.889 5.37467 12.1111 6.00842 11.7108 6.3188L9.74998 8.08301C9.66215 8.15053 9.59796 8.24414 9.56662 8.35041C9.53529 8.45667 9.53842 8.57013 9.57556 8.67451L10.2916 11.7095C10.4661 12.197 9.90165 12.6157 9.47481 12.3156L6.81144 10.6256C6.72023 10.5615 6.61146 10.5271 6.49998 10.5271C6.3885 10.5271 6.27973 10.5615 6.18852 10.6256L3.52515 12.3156C3.09886 12.6157 2.5339 12.1964 2.70831 11.7095L3.4244 8.67451C3.46154 8.57013 3.46467 8.45667 3.43334 8.35041C3.402 8.24414 3.33782 8.15053 3.24998 8.08301L1.28915 6.3188C0.888314 6.00842 1.11148 5.37467 1.61956 5.37467H4.37502C4.48351 5.37504 4.58956 5.34245 4.67912 5.28122C4.76869 5.22 4.83755 5.13302 4.87661 5.0318L5.99786 1.92588H5.9984Z" fill="#D5B195" stroke="#D5B195" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="training-card-reviews">
                  {`${reviews} ${reviews === 1 ? `recenzja` : `recenzji`}`}
                </div>
              </div>
            </div>
            <h3 className="training-card-title">
              {sliderTitle}
              {sliderSubtitle && (
                <span className="training-card-subtitle">
                  {sliderSubtitle}
                </span>
              )}
            </h3>
            <button type="button" className="training-card-button" onClick={event => onButtonClick(event, id)}>
              Więcej informacji
            </button>
          </div>
        </article>
      );
    }}
  />
);

export default Card;
